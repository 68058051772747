import React from "react";
import { Card, DivButton, DivColumn, DivRow } from "./styles";
import { FaArrowLeft, FaInstagram, FaTiktok } from "react-icons/fa";
import Logo from "../../assets/user.webp";
import { toReal } from "../../utils/currency";
import { formatDate, onlyNumber } from "../../utils/helpers";

const CardSolicitation = ({
    mySolicitation,
    titulo,
    criacao,
    descricao,
    sobre_influencer,
    valor,
    foto,
    nome,
    cidade,
    estado,
    tiktok,
    instagram,
    link_portifolio,
    whatsapp,
    status,
    justificativa,
    step,
    setStep,
    openTikTok,
    openInstagram,
    handlePortifolio,
    handleAlterStatus,
    loadingStatus,
}) => {


  return (
    <Card>
      <FaArrowLeft
        size={22}
        onClick={() => setStep(1)}
        style={{ cursor: "pointer" }}
      />
      <DivColumn style={{ margin: "20px 8px 8px" }}>
        <h2>{titulo}</h2>

        <p>
          Publicado:{" "}
          {criacao
            ? formatDate(criacao)
            : ""}
        </p>
        {descricao ? (
            <DivColumn style={{ marginTop: 20, marginBottom: 8 }}>
            <h3>Descrição do pacote:</h3>
            <p>{descricao}</p>
            </DivColumn>
        ) : null}

        {justificativa ? (
            <DivColumn style={{ marginTop: 20, marginBottom: 10 }}>
             <h2>Por que eu?</h2>
             <p>{justificativa}</p>
            </DivColumn>
        ) : null}

        {sobre_influencer ? (
          <DivColumn style={{ marginTop: 20, marginBottom: 10 }}>
            <h2>Por que eu?</h2>
            <p>{sobre_influencer}</p>
          </DivColumn>
        ) : null}

        <p>
          Valor:{" "}
          {valor
            ? toReal(+valor)
            : ""}
        </p>

        <DivRow>
          <DivRow className="imageProfile" style={{ width: "60%" }}>
            <img
              src={
                foto
                  ? foto
                  : Logo
              }
              height={70}
              width={70}
              alt="Foto do influencer"
            />
            <DivColumn>
              <h3>{nome}</h3>
              <p className="cidade">
                {cidade}
                {estado
                  ? `, ${estado}`
                  : null}
              </p>
              <DivRow>
                {tiktok ? (
                  <FaTiktok
                    size={22}
                    onClick={() =>
                      openTikTok(tiktok)
                    }
                    style={{ marginRight: 6, cursor: "pointer" }}
                  />
                ) : null}
                {instagram ? (
                  <FaInstagram
                    size={26}
                    onClick={() =>
                      openInstagram(instagram)
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : null}
              </DivRow>
            </DivColumn>
          </DivRow>
          <DivColumn className="buttonsCreator" style={{ width: "40%" }}>
            {link_portifolio ? (
              <DivButton
                setBackgroundColor={"var(--white)"}
                setShadow={"var(--black)"}
                onClick={() =>
                  handlePortifolio(link_portifolio)
                }
              >
                Ver Portfólio
              </DivButton>
            ) : null}

            {whatsapp ? (
              <DivButton
                setBackgroundColor={"var(--white)"}
                setShadow={"var(--black)"}
              >
                <a
                  href={`https://wa.me/${onlyNumber(
                    whatsapp
                  )}?text=Ol%C3%A1!%20Vi%20seu%20portf%C3%B3lio%20na%20plataforma%20Boraver%20e%20gostaria%20de%20saber%20mais%20sobre%20como%20voc%C3%AA%20trabalha.%20Pode%20me%20explicar%3F`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "var(--black)",
                  }}
                >
                  Enviar Mensagem
                </a>
              </DivButton>
            ) : null}
          </DivColumn>
        </DivRow>
        {!mySolicitation && (status === "Nova Oferta" || status === "Pendente") ? (
          <DivRow
            className="actionsSolicitation"
            style={{
              marginTop: 30,
              marginBottom: 30,
              justifyContent: "flex-end",
            }}
          >
            <DivButton
              setBackgroundColor={"var(--yellow)"}
              disabled={loadingStatus}
              onClick={() => handleAlterStatus("Em Andamento")}
              style={{ width: "48%" }}
            >
              Aceitar Proposta
            </DivButton>
            <DivButton
              setBackgroundColor={"var(--graycard)"}
              setShadow={"var(--black)"}
              disabled={loadingStatus}
              onClick={() => handleAlterStatus("recusado")}
              style={{ width: "48%" }}
            >
              Recusar
            </DivButton>
          </DivRow>
        ) : null}
      </DivColumn>
    </Card>
  );
};

export default CardSolicitation;
