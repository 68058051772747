import React from "react";
import Logo from "../../assets/user.webp";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { DivRow } from "./styles";

const RenderSolicitation = ({solicitations, openTikTok, openInstagram, handleSelectSolicitation}) => {
  return solicitations.map((solicitation, index) => (
    <div className="influencerSolicitation" key={index}>
      <div className="solicitation">
        <img
          src={
            solicitation.influencidor.profile_picture
              ? solicitation.influencidor.profile_picture
              : Logo
          }
          alt="client-profilepic"
          // onClick={() =>
          //     handleNavigate(
          //         solicitation,
          //         `${solicitation.cliente.id}${solicitation.influencidor.id}${solicitation.id}`
          //     )
          // }
        />
        <div className="column">
          <h6 className="creator">{solicitation.influencidor.nome}</h6>
          <p className="cidade">
            {solicitation.influencidor.cidade}
            {solicitation.influencidor.estado
              ? `, ${solicitation.influencidor.estado}`
              : ""}
          </p>
          <div className="row">
            {solicitation.influencidor.tiktok ? (
              <FaTiktok
                size={24}
                onClick={() => openTikTok(solicitation.influencidor.tiktok)}
                style={{ marginRight: 6, cursor: 'pointer' }}
              />
            ) : null}
            {solicitation.influencidor.instagram ? (
              <FaInstagram
                size={26}
                onClick={() =>
                  openInstagram(solicitation.influencidor.instagram)
                }
                style={{ cursor: 'pointer' }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="solicitation details"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {solicitation.titulo ? (
          <p style={{ fontSize: 18, fontWeight: 600 }}>{solicitation.titulo}</p>
        ) : solicitation.campanha_detalhes &&
          solicitation.campanha_detalhes.nome_campanha ? (
          <p style={{ fontSize: 18, fontWeight: 600 }}>
            {solicitation.campanha_detalhes.nome_campanha}
          </p>
        ) : null}

        <DivRow className="actionSolicitation" style={{ display: "flex", justifyContent: "flex-end"}}>
          {solicitation.status === "Nova Oferta" &&
          solicitation.solicitante === 0 ? (
            <p className="solicitationStatus newSolicitation">
              Status: Aguardando Resposta do Influencer
            </p>
          ) : solicitation.status === "Nova Oferta" &&
            solicitation.solicitante === 1 ? (
            <p className="solicitationStatus newSolicitation">
              Status: Aguardando Sua Resposta
            </p>
          ) : solicitation.status === "Em Andamento" ? (
            <p className="solicitationStatus aceita">Status: Aceita</p>
          ) : solicitation.status === "recusado" || solicitation.status === "Rejeitado" ? (
            <p className="solicitationStatus recusado">Status: Recusado</p>
          ) : (
            <p className="solicitationStatus aceita">
              Status: {solicitation.status}
            </p>
          )}

          <Button
            setBackgroundColor={"var(--yellow)"}
            setShadow={"var(--graycard)"}
            style={{ marginRight: 0 }}
            onClick={() =>
              handleSelectSolicitation(
                solicitation,
                solicitation.solicitante === 0 ? true : false,
                false
              )
            }
          >
            Ver Proposta
          </Button>
        </DivRow>
      </div>
      {/* {solicitation.status === "Pago Aguardando Serviço" ? (
                      solicitation.influencidor && solicitation.influencidor.whatsapp ? (
                          <button
                              className="primary"
                              style={{
                                  cursor: "pointer",
                                  padding: "10px 12px",
                              }}
                          >
                              <a
                                  style={{
                                      color: "white",
                                      fontWeight: 500,
                                      padding: "10px 12px",
                                      textDecoration: "none",
                                  }}
                                  target="_blank"
                                  href={`https://api.whatsapp.com/send?phone=${onlyNumber(solicitation.influencidor.whatsapp)}`}
                              >
                                  Falar com Creator
                              </a>
  
                          </button>
                      ) :
                          <div className="emptySpace"></div>
                  ) : solicitation.status === "Nova Oferta" &&
                      solicitation.solicitante === 1 ? (
                      <Button
                          setBackgroundColor={'var(--yellow)'}
                          onClick={() => handleSelectSolicitation(solicitation, false, false)}
                      >
                          Ver Proposta
                      </Button>
                  ) : solicitation.status === "Concluído" ? (
                      <Button
                          setBackgroundColor={'var()'}
                          onClick={() => handleSelectSolicitation(solicitation, true, true)}
                      >
                          Ver Proposta
                      </Button>
                  ) : solicitation.status === "Em Andamento" ? (
                      <button
                          className="primary"
                          onClick={() => handleNavigate(
                              solicitation,
                              `${solicitation.cliente.id}${solicitation.influencidor.id}${solicitation.id}`
                          )}
                      >
                          Pagar
                      </button>
                  ) : (
                      <div className="emptySpace"></div>
                  )} */}
    </div>
  ));
};

export default RenderSolicitation;
