import React, { useEffect, useState } from "react";
import { Container, DivRow } from "./styles";
import { BsSearch } from "react-icons/bs";
import Button from "../Button/Button";
import { useAuth } from "../../providers/auth";

let typingTimeout;

const InputSearch = ({ name, placeholder, submit, bgColor, inputValue="" }) => {
  const { auth } = useAuth;
  const [search, setSearch] = useState(inputValue);

  useEffect(() => {
    if (typingTimeout) clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      submit(search);
    }, [500]);
  }, [search]);

  return (
    <Container bgColor={bgColor} logged={auth ? true : false}>
      <DivRow>
        <BsSearch size={28} fill="#B8B8B8" />
        <input
          name={name}
          value={search}
          placeholder={placeholder}
          onChange={({ target }) => setSearch(target.value)}
        />
        {/* <Button
          setBackgroundColor={"var(--yellow)"}
          setColor={"var(--black)"}
          style={{ border: "none", marginBottom: 0 }}
          onClick={() => submit()}
        >
          Buscar
        </Button> */}
      </DivRow>
    </Container>
  );
};

export default InputSearch;
