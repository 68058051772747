export const Redes_Sociais = {
    INSTAGRAM: 1,
    TIKTOK: 2,
    YOUTUBE: 3,
}

export const Tipo_Remuneracao = {
    DINHEIRO: 1,
    VOUCHER: 2,
    EM_PRODUTOS: 3
}

export const Status_Campanha = {
    PENDENTE: 0,
    APROVADO: 1,
    REJEITADO: 2
}

export const Estados = {
    TODOS: 0,
    ACRE: 1,
    ALAGOAS: 2,
    AMAPA: 3,
    AMAZONAS: 4,
    BAHIA: 5,
    CEARA: 6,
    DISTRITO_FEDERAL: 7,
    ESPIRITO_SANTO: 8,
    GOIAS: 9,
    MARANHAO: 10,
    MATO_GROSSO: 11,
    MATO_GROSSO_DO_SUL: 12,
    MINAS_GERAIS: 13,
    PARA: 14,
    PARAIBA: 15,
    PARANA: 16,
    PERNAMBUCO: 17,
    PIAUI: 18,
    RIO_DE_JANEIRO: 19,
    RIO_GRANDE_DO_NORTE: 20,
    RIO_GRANDE_DO_SUL: 21,
    RONDONIA: 22,
    RORAIMA: 23,
    SANTA_CATARINA: 24,
    SAO_PAULO: 25,
    SERGIPE: 26,
    TOCANTINS: 27,
};